<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <v-row>
          <v-col cols="12" md="10" class="pl-5">
            <PageHeader :title="title" />
          </v-col>
          <v-col cols="12" md="2" align="end" class="mt-1" v-if="canConfig">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="to-right"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  @click="configEndpoint()"
                >
                  <v-icon size="30px">{{ settingsIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Configurar URL de conexión</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="opcionesMenuItems"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar tablero</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteTablero(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar tablero</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditOpcionMenuAnalytics
        :editOpcionItem="editOpcionItem"
        @closeAndReload="closeAndReload"
      ></EditOpcionMenuAnalytics>
    </v-dialog>
    <v-dialog
      v-if="openModalConfigURL"
      v-model="openModalConfigURL"
      max-width="60%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <ConfiguracionUrlApiAnalytics
        @closeAndReload="closeAndReload"
      ></ConfiguracionUrlApiAnalytics>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'25%'"
      :isLoading="loadingDeleteBtn"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import Ayuda from "@/components/shared/Ayuda.vue";
import EditOpcionMenuAnalytics from "@/components/modules/analytics/EditOpcionMenuAnalytics.vue";
import ConfiguracionUrlApiAnalytics from "@/components/modules/analytics/ConfiguracionUrlApiAnalytics.vue";

export default {
  name: "AnalyticsConfig",
  components: {
    PageHeader,
    DeleteDialog,
    EditOpcionMenuAnalytics,
    ConfiguracionUrlApiAnalytics,
    Ayuda
  },
  data: () => ({
    rules: rules,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    settingsIcon: enums.icons.SETTINGS,
    optionCode: enums.webSiteOptions.CONFIGURACION_TABLEROS_ANALYTICS,
    title: "Configuración de opciones de menú y links",
    titleDelete: "¿Eliminar tablero?",
    search: "",
    isLoading: false,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    showDeleteModal: false,
    idToDelete: null,
    opcionesMenuItems: [],
    editOpcionItem: {},
    headers: [
      {
        text: "Tableros",
        value: "optionTitle",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    canConfig: false,
    openModalEdit: false,
    openModalConfigURL: false,
    loadingDeleteBtn: false
  }),
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.setPermisos();
    this.loadTablerosAnalytics();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getConfiguracionTableros: "analytics/getConfiguracionTableros",
      deleteConfiguracionTableroAnalytics:
        "analytics/deleteConfiguracionTableroAnalytics"
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.EDITAR_TABLERO_ANALYTICS:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_TABLERO_ANALYTICS:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_TABLERO_ANALYTICS:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.CONFIGURAR_API_ANALYTICS:
            this.canConfig = true;
            break;
          default:
            break;
        }
      });
    },
    async loadTablerosAnalytics() {
      const response = await this.getConfiguracionTableros();
      this.opcionesMenuItems = response;
    },
    deleteTablero(item) {
      this.showDeleteModal = true;
      this.itemToDelete = item.configId;
    },
    async confirmDelete() {
      this.loadingDeleteBtn = true;
      try {
        const response = await this.deleteConfiguracionTableroAnalytics(
          this.itemToDelete
        );
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.loadTablerosAnalytics();
          this.loadingDeleteBtn = false;
        }
      } catch {
        this.loadingDeleteBtn = false;
        this.showDeleteModal = false;
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.editOpcionItem = item;
    },
    configEndpoint() {
      this.openModalConfigURL = true;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.openModalConfigURL = false;
      this.loadTablerosAnalytics();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
