<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Nombre de tabla -->
            <v-col cols="12" class="py-0" v-if="this.editOpcionItem == null">
              <v-autocomplete
                v-model="tableroSelected"
                :items="tableroItems"
                item-text="value"
                item-value="id"
                outlined
                clearable
                dense
                :rules="rules.required"
                autocomplete="on"
                label="Nombre del tablero"
                return-object
                @change="changeTableroSelected"
              >
              </v-autocomplete>
            </v-col>
            <!-- Nombre para otros tableros -->
            <v-col cols="12" class="py-0" v-if="this.isOtrosTableros">
              <v-text-field
                type="text"
                v-model="nombreOtrosTableros"
                outlined
                dense
                clearable
                :rules="
                  rules.required.concat(
                    rules.maxLength(nombreOtrosTableros, 100),
                    rules.requiredTrim(nombreOtrosTableros)
                  )
                "
                label="Nombre de opción de menú"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Link de tabla -->
            <v-col cols="12" class="py-0">
              <v-text-field
                type="text"
                v-model="linkTabla"
                outlined
                dense
                clearable
                :rules="rules.required.concat(rules.requiredTrim(linkTabla), rules.validateUrl)"
                label="Link de acceso al tablero"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="loadingSaveBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
export default {
  name: "EditOpcionMenuAnalytics",
  props: {
    editOpcionItem: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      rules: rules,
      optionCodeAnalytics: enums.webSiteOptions.ANALYTICS,
      optionCodeOtrosTableros: enums.webSiteOptions.ANALYTICS_OTROS_TABLEROS,
      formEditTitle: `Editar tablero: `,
      isFormValid: false,
      isOtrosTableros: false,
      loadingSaveBtn: false,
      nombreOtrosTableros: null,
      tableroSelected: null,
      tableroItems: null,
      linkTabla: null,
      dataSaveEdit: null
    };
  },
  async created() {
    this.setSelects();
    if (this.editOpcionItem != null) {
      this.setTabla();
    } else {
      this.newTabla();
    }
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getWebSiteOptionsByParent: "analytics/getWebSiteOptionsByParent",
      saveConfiguracionTableros: "analytics/saveConfiguracionTableros",
      saveOpcionDeMenu: "analytics/saveOpcionDeMenu"
    }),
    async setSelects() {
      const response = await this.getWebSiteOptionsByParent({
        parentOptionCode: this.optionCodeAnalytics
      });
      this.tableroItems = response.filter(
        x =>
          x.description != enums.webSiteOptions.CONFIGURACION_TABLEROS_ANALYTICS
      );
    },
    setTabla() {
      this.formEditTitle = `Editar tablero: ${this.editOpcionItem.optionTitle}`;
      this.tableroSelected = this.editOpcionItem.optionId;
      this.linkTabla = this.editOpcionItem.linkDesencriptado;
    },
    async newTabla() {
      this.formEditTitle = "Nueva opción de menú";
    },
    changeTableroSelected() {
      if (this.tableroSelected.value == "Otros tableros") {
        this.isOtrosTableros = true;
      } else {
        this.isOtrosTableros = false;
        this.nombreOtrosTableros = null;
      }
    },
    async saveEdit() {
      this.loadingSaveBtn = true;
      if (this.isOtrosTableros) {
        const dataOpcion = {
          title: this.nombreOtrosTableros,
          parentOptionCode: this.optionCodeOtrosTableros,
          optionOrder: 1
        };
        try {
          const res = await this.saveOpcionDeMenu(dataOpcion);
          this.tableroSelected = res.data.data;
        } catch (error) {
          this.setAlert({
            type: "error",
            message: "Se produjo un error al crear la tabla."
          });
        }
        this.dataSaveEdit = {
          configId: this.editOpcionItem ? this.editOpcionItem.configId : 0,
          optionId: this.tableroSelected,
          linkDesencriptado: this.linkTabla
        };
      } else {
        this.dataSaveEdit = {
          configId: this.editOpcionItem ? this.editOpcionItem.configId : 0,
          optionId: this.editOpcionItem
            ? this.tableroSelected
            : this.tableroSelected.id,
          linkDesencriptado: this.linkTabla
        };
      }
      try {
        const response = await this.saveConfiguracionTableros(
          this.dataSaveEdit
        );
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
        }
      } catch (error) {
        this.loadingSaveBtn = false;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
