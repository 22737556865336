<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- URL de api -->
            <v-col cols="11" class="py-0">
              <v-text-field
                type="text"
                v-model="urlApi"
                outlined
                dense
                clearable
                label="URL"
                :rules="
                  urlValidated != null
                    ? [urlValidated ? true : 'La conexión fué rechazada']
                    : []
                "
                :loading="loadingValidateConnection"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Botón de prueva -->
            <v-col cols="1" class="pt-1 pl-0">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="probarConexionAPI()"
                    v-bind="attrs"
                    color="green"
                    v-on="on"
                    size="30"
                  >
                    mdi-check-decagram
                  </v-icon>
                </template>
                <span>Probar conexión</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid || urlValidated == null"
          :loading="loadingSaveBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ConfiguracionUrlApiAnalytics",
  data() {
    return {
      formEditTitle: "URL de la API analytics",
      isFormValid: false,
      loadingSaveBtn: false,
      urlApi: "",
      loadingValidateConnection: false,
      urlValidated: null
    };
  },
  mounted() {
    this.getConnection();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      saveUrlApiAnalytics: "analytics/saveUrlApiAnalytics",
      proveAnalyticsConnection: "analytics/proveAnalyticsConnection",
      getAnalyticsConnection: "analytics/getAnalyticsConnection"
    }),
    async getConnection() {
      const response = await this.getAnalyticsConnection();
      if (response != "") this.urlApi = response;
    },
    async saveEdit() {
      this.loadingSaveBtn = true;
      const data = { url: this.urlApi };
      try {
        const res = await this.saveUrlApiAnalytics(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
        }
      } catch (error) {
        this.loadingSaveBtn = false;
      }
    },
    async probarConexionAPI() {
      this.loadingValidateConnection = true;
      const data = { url: this.urlApi };
      try {
        const res = await this.proveAnalyticsConnection(data);
        this.setAlert({ type: "success", message: "Conexión exitosa." });
        this.urlValidated = true;
        this.isFormValid = true;
      } catch (error) {
        this.setAlert({ type: "error", message: "Conexión rechazada." });
        this.urlValidated = false;
        this.isFormValid = false;
      }
      this.loadingValidateConnection = false;
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
